const mallBaseURL =
  process.env.NODE_ENV === "development"
    ? /mall-proxy/
    : "https://mall.xueersi.com/";

const loginBaseURL =
  process.env.NODE_ENV === "development"
    ? /login-proxy/
    : "https://login.xueersi.com/";

const apiBaseURL =
  process.env.NODE_ENV === "development"
    ? /api-proxy/
    : "https://api.xueersi.com/";

const wwwBaseURL =
  process.env.NODE_ENV === "development"
    ? /www-proxy/
    : "https://online.xueersi.com/";

export { mallBaseURL, loginBaseURL, apiBaseURL, wwwBaseURL };
