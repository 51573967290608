import Vue from 'vue'
import main from './progress.vue'

const Component = Vue.extend(main)

const vm = new Component()

vm.$mount()

if (!vm.$isServer) {
  document.body.appendChild(vm.$el)
}

const progress = {}

let timer = null

const increase = (cut) => vm.percent = Math.min(99, vm.percent + cut)

const hidden = function () {
  clearInterval(timer)
  setTimeout(() => vm.show = false, 200)
  setTimeout(() => vm.percent = 0, 600)
}

let isLoading = false, queue = []

progress.start = function () {
  if (isLoading) return queue.push(0)

  vm.percent = 0
  vm.show = true
  vm.canSuccess = true

  timer = setInterval(increase, 100, 4 * Math.random() + 1)

  isLoading = true
}

progress.resolve = function () {
  if (queue.length) queue.pop()
  if (queue.length) return
  vm.percent = 100
  hidden()
  isLoading = false
}

progress.reject = function () {
  if (queue.length) queue.pop()
  vm.canSuccess = false
  if (queue.length) return
  vm.percent = 100
  hidden()
  isLoading = false
}

export default progress
