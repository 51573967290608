import progress from '@components/progress'
import axios from 'axios'
// import cached from '@request/create-cached'

const request = axios.create({
  maxRedirects: 0,
  withCredentials: true,
})

request.interceptors.request.use((config) => {
  return config
})

const createRequest = (_request, options) => new Promise((resolve, reject) => {
  const { needHeaders } = options
  _request
    .then((res) => {
      const data = res.data
      if (needHeaders) data.headers = res.headers
      resolve(data)
      progress.resolve()
    })
    .catch((err) => {
      reject(err)
      progress.reject()
    })
})

const methods = [ 'get', 'post', 'request' ]

const net = { defaults: request.defaults }

for (let item of methods) {
  net[item] = (arg, ...args) => {
    progress.start()
    let needHeaders = false
    args.forEach((_item) => {
      if (_item.needHeaders) needHeaders = true
      return _item
    })
    return createRequest(item === 'request' ? request(arg, ...args) : request[item](arg, ...args), { needHeaders })
  }
}

export default net
