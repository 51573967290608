<template>
  <div>
    <div>
      <div class="slide-bar">
        <template v-if="sideList.length">
          <div
            v-for="(slideItem, slideIndex) in sideList"
            :key="slideIndex"
            class="slide-container"
          >
            <!-- 精品课按钮 -->
            <a
              v-if="slideItem.type === 1"
              :style="'background-image: url('+ slideItem.icon +')'"
              class="gift"
              href="javascript:"
              @click="handleGift(slideItem)"
            />
            <!-- 扫码下载 -->
            <div
              v-if="slideItem.type === 2"
              class="slide-bar__item"
              @mouseout="btnout"
              @mouseover="btnhover(slideItem)"
            >
              <div
                v-show="hover === slideItem.order"
                :style="'background-image: url('+ slideItem.icon_hover +')'"
                class="slide-bar__item__img"
              />
              <div
                v-show="hover !== slideItem.order"
                :style="'background-image: url('+ slideItem.icon +')'"
                class="slide-bar__item__img"
              />
              <!-- <div :style="styleObject" :class="['slide-bar__item__img', hover === 'scan' ? 'scan--hover' : 'scan']"></div> -->
              <div class="slide-bar__item__text">
                {{ slideItem.name }}
              </div>
              <div
                v-if="slideItem.img"
                class="slide-bar__item__scan">
                <img
                  :src="slideItem.img"
                  height="165"
                  width="165"
                >
                <p
                  class="slide-bar__item__scan__text"
                  v-html="slideItem.content"
                />
              </div>
            </div>

            <!-- 购物车 -->
            <div
              v-if="slideItem.type === 3"
              class="slide-bar__item"
              @click="handleShoppingCart(slideItem)"
              @mouseout="btnout"
              @mouseover="btnhover(slideItem)"
            >
              <div
                v-show="hover === slideItem.order"
                :style="'background-image: url('+ slideItem.icon_hover +')'"
                class="slide-bar__item__img"
              >
                <div
                  v-if="cartCount"
                  class="goods-car__count"
                >
                  {{ cartCount }}
                </div>
              </div>
              <div
                v-show="hover !== slideItem.order"
                id="shoppingCart"
                :style="'background-image: url('+ slideItem.icon +')'"
                class="slide-bar__item__img"
              >
                <div
                  v-if="cartCount"
                  class="goods-car__count"
                >
                  {{ cartCount }}
                </div>
              </div>
              <div class="slide-bar__item__text">
                {{ slideItem.name }}
              </div>
            </div>

            <!-- 咨询按钮 -->
            <div
              v-if="slideItem.type === 4"
              class="slide-bar__item"
              @click="contactClick(slideItem, $event)"
              @mouseout="btnout"
              @mouseover="btnhover(slideItem)"
            >
              <div
                v-show="hover === slideItem.order"
                :style="'background-image: url('+ slideItem.icon_hover +')'"
                class="slide-bar__item__img"
              />
              <div
                v-show="hover !== slideItem.order"
                :style="'background-image: url('+ slideItem.icon +')'"
                class="slide-bar__item__img"
              />
              <!-- <div :class="['slide-bar__item__img', hover === 'zixun' ? 'zixun--hover' : 'zixun']"></div> -->
              <div class="slide-bar__item__text">
                {{ slideItem.name }}
              </div>
              <div v-if="slideItem.content" class="slide-bar__item__tips">
                {{ slideItem.content }}
              </div>
            </div>

            <!-- 反馈按钮 -->
            <div
              v-if="slideItem.type === 5"
              class="slide-bar__item"
              @click="clickFeedback(slideItem)"
              @mouseout="btnout"
              @mouseover="btnhover(slideItem)"
            >
              <div
                v-show="hover === slideItem.order"
                :style="'background-image: url('+ slideItem.icon_hover +')'"
                class="slide-bar__item__img"
              />
              <div
                v-show="hover !== slideItem.order"
                :style="'background-image: url('+ slideItem.icon +')'"
                class="slide-bar__item__img"
              />
              <!-- <div :class="['slide-bar__item__img', hover === 'fankui' ? 'fankui--hover' : 'fankui']"></div> -->
              <div class="slide-bar__item__text">
                {{ slideItem.name }}
              </div>
              <div v-if="slideItem.content" class="slide-bar__item__tips">
                {{ slideItem.content }}
              </div>
            </div>
            <!-- 其它按钮 -->
            <div
              v-if="showSideBarItem(slideItem.type)"
              class="slide-bar__item"
              @click="clickJump(slideItem)"
              @mouseout="btnout"
              @mouseover="btnhover(slideItem)"
            >
              <div
                v-show="hover === slideItem.order"
                :style="'background-image: url('+ slideItem.icon_hover +')'"
                class="slide-bar__item__img"
              />
              <div
                v-show="hover !== slideItem.order"
                :style="'background-image: url('+ slideItem.icon +')'"
                class="slide-bar__item__img"
              />
              <!-- <div :class="['slide-bar__item__img', hover === 'fankui' ? 'fankui--hover' : 'fankui']"></div> -->
              <div class="slide-bar__item__text">
                {{ slideItem.name }}
              </div>
              <div
                v-if="slideItem.content"
                class="slide-bar__item__tips"
              >
                {{ slideItem.content }}
              </div>
            </div>
            <div
              v-if="slideIndex !== 0 && slideIndex !== sideList.length - 1"
              class="thin-line"
            />
          </div>
        </template>

        <div
          v-show="showTop&&sideList.length"
          class="thin-line"
        />

        <!-- 返回顶部按钮 -->
        <div
          v-show="showTop"
          class="slide-bar__item"
          @click="goTop"
          @mouseout="btnout"
          @mouseover="btnhover('arrow')"
        >
          <div :class="['slide-bar__item__img', hover === 'arrow' ? 'arrow--hover' : 'arrow']" />
          <div class="slide-bar__item__top" />
          <div class="slide-bar__item__tips">
            返回顶部
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="quyuURL && !qiyuReady && !preLoad"
      class="qiyu-customer"
    >
      <div class="xes-loading">
        <p class="xes-loading-tips">
          在线客服加载中
        </p>
      </div>
    </div>
    <iframe
      v-if="quyuURL"
      v-show="quyuIframeShow && !preLoad"
      ref="qiyuCustomer"
      :src="quyuURL"
      class="qiyu-customer"
      frameborder="0"
    />
  </div>
</template>

<script>
import axios from 'axios';
import { is401, is404 } from '../../util/isErrorStatus';
import envConfigFn from './env';
import { wwwBaseURL } from "../../networks/_base";

// import { Base64 } from 'js-base64'

export default {
  name: 'xes-mall-sidebar',
  props: {
    sideList: {
      type: Array,
      default: () => [],
    },
    // 客服传参
    qiyuParams: {
      type: Object,
      default: () => {
      },
    },
    // 是否登录【课程详情页结合customCartCount参数使用】
    hasLogin: {
      type: Boolean,
      default: true,
    },
    // 购物车角标数量
    customCartCount: {
      type: String,
      default: '',
    },
    isShowSlideBar: {
      type: Boolean,
      default: false,
    },
    // 业务id，默认素质
    bsenv: {
      type: Number,
      default: 10,
    },
    debugger: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isLogin: true,
      showCart: true,
      showTop: false,
      cartCount: '',
      quyuURL: '',
      quyuIframeShow: false,
      qiyuReady: false,
      preLoad: true,
      hover: '',
      loadLogCompelete: true,
      hoverName: [],
    };
  },
  created() {
    this.envConfig = envConfigFn(this.bsenv);
  },
  async mounted() {
    window.addEventListener('scroll', () => {
      const scrollTop = window.pageYOffset
        || document.documentElement.scrollTop
        || document.body.scrollTop
        || 0;
      if (scrollTop > 100) {
        this.showTop = true;
      } else {
        this.showTop = false;
      }
    }, true);

    if (!this.customCartCount) {
      await this.getCartCount();
    }

    // 加载新版日志
    if (!window.__XES_LOG__) {
      this.loadLogCompelete = false;
      await this.loadLogSDK();
      this.loadLogCompelete = true;
    }
    // 展现日志手动上报
    window.__XES_LOG__.loadMsg({ key: 'xes-mall-sidebar-show' });
  },
  computed: {
    showSideBarItem() {
      return function (sideItemType) {
        const isShowSideBarItem = [1, 2, 3, 4, 5].toString().indexOf(sideItemType) > -1
        return !isShowSideBarItem
      }
    }
  },
  watch: {
    customCartCount(val) {
      const num = +val;
      if (num > 0) {
        this.cartCount = val;
      }
    },
    hasLogin(val) {
      this.isLogin = val;
    },
    isShowSlideBar: {
      immediate: true,
      handler(newVal) {
        if(newVal) {
          const chatData = this.sideList.find(item => {
            return item.type === 4
          })
          if (chatData && !this.quyuURL) {
              this.preLoadChat(chatData)
          }
        }
      }
    },
    sideList: {
      immediate: true,
      handler(newVal) {
        if(this.isShowSlideBar) {
          const chatData = newVal.find(item => {
            return item.type === 4
          })
          if (chatData && !this.quyuURL) {
              this.preLoadChat(chatData)
          }
        }
      }
    },
    showTop(val) {
      if (val) {
        // 日志
        window.__XES_LOG__.clickMsg({
          params: {
            key: 'xes-mall-sidebar',
            clickid: '1.6',
          },
        });
      }
    },
  },
  methods: {
    async getCartCount() {
      try {
        // const cartSwitchUrl = this.debugger ? '/mall-proxy/cartSwitch' : `${this.envConfig.MALL_API_BASE}cartSwitch`
        // const cartCountUrl = this.debugger ? '/mall-proxy/cart/getCartCount' : `${this.envConfig.MALL_API_BASE}cart/getCartCount`
        // const params = {
        //   'version': (new Date()).getTime(),
        //   'X-Businessline-Id': this.bsenv,
        //   withCredentials: true
        // }
        const { data: switch_res } = await axios.get(`${wwwBaseURL}mall/cartSwitch?version=${(new Date()).getTime()}&X-Businessline-Id=${this.bsenv}`)
        if (switch_res && switch_res.result && switch_res.result.stat === 1) {
          this.showCart = true;
          try {
            const { data: count_data } = await axios.get(`${wwwBaseURL}mall/cart/getCartCount?version=${(new Date()).getTime()}&X-Businessline-Id=${this.bsenv}`, {
              withCredentials: true
            })
            if (is401(count_data)) {
              this.isLogin = false;
              return;
            }
            if (is404(count_data)) {
              return;
            }
            if (count_data && count_data.result
              && count_data.result.data && count_data.result.data.num) {
              const { num } = count_data.result.data;
              if (+num > 0) {
                this.cartCount = num;
              }
            }
          } catch (error) {
            if (error.response && error.response.status === 401) {
              this.isLogin = false;
            }
            // console.log('获取购物车数量api throw error==', error)
          }
        } else {
          this.showCart = false;
        }
      } catch (error) {
        // console.log('获取购物车开关api throw error==', error)
      }
    },
    btnhover(slideItem) {
      if (typeof slideItem === 'string') {
        this.hover = slideItem;
        return
      }
      this.hover = slideItem.order;
    },
    btnout() {
      this.hover = '';
    },
    handleShoppingCart(slideItem) {
      if (!slideItem.url) return
      if (!this.isLogin) {
        // window.location.href = `${this.envConfig.LOGIN_API_BASE}/login/${Base64.encode(window.location.href)}`
        window.location.href = `${this.envConfig.LOGIN_API_BASE}?redirect_url=${encodeURIComponent(window.location.href)}`;
        return;
      }
      if (slideItem.target === 2) {
        window.open(`${slideItem.url}`);
      } else {
        window.location.href = slideItem.url
      }

      // 日志
      window.__XES_LOG__.clickMsg({
        params: {
          key: 'xes-mall-sidebar',
          eventname: '购物车-点击',
          clickid: 'click_lMsyVWiz', // 1.2
        },
      });
    },
    preLoadChat(item) {
      if (!item.url || !this.preLoad) return;
      let parmas = '';
      const hasSearch = item.url.indexOf('?') !== -1;
      this.preLoad = true
      this.quyuURL = `${item.url}${hasSearch?'&':'?'}sysNum=4a1c52959a974dd497260f4e0d50f1c9&channelFlag=${window.channelFlag || 2}${parmas}`;
      window.console.log('客服链接==', this.quyuURL);

      if (item.url && this.quyuURL) {
        this.$nextTick(() => {
          const iframe = this.$refs.qiyuCustomer;
          if (iframe.attachEvent) { // ie
            iframe.attachEvent('onload', () => {
              this.qiyuReady = true;
              window.addEventListener('message', (e) => {
                if (e.data.indexOf && e.data.indexOf('ACK') === 0) return;
                // 点击七鱼客服面板最小化按钮
                if (e && e.data) {
                  const data = this.parseJSON(e.data);
                  if (data && data.closeChat) {
                    this.quyuIframeShow = false;
                  }
                }
              }, false);
            });
          } else { // 其他浏览器
            iframe.onload = () => {
              this.qiyuReady = true;
              window.addEventListener('message', (e) => {
                if (e.data.indexOf && e.data.indexOf('ACK') === 0) return;
                // 点击七鱼客服面板最小化按钮
                if (e && e.data) {
                  const data = this.parseJSON(e.data);
                  if (data && data.closeChat) {
                    this.quyuIframeShow = false;
                  }
                }
              }, false);
            };
          }
        });
      }
    },
    contactClick(item, preLoad) {
      if (!item.url) return
      this.preLoad = false
      // 日志
      window.__XES_LOG__.clickMsg({
        params: {
          key: 'xes-mall-sidebar',
          eventname: '客服咨询-点击',
          clickid: 'click_1oIGxMaN',// 1.3
        },
      });

      if (this.quyuURL) {
        if (!this.quyuIframeShow) {
          this.$refs.qiyuCustomer.contentWindow.postMessage({
            type: 'qiyu-show',
          }, '*');
          this.quyuIframeShow = true;
          return;
        }
      }
      let parmas = '';

      if (this.qiyuParams && Object.keys(this.qiyuParams).length) {
        for (const key in this.qiyuParams) {
          if (this.qiyuParams.hasOwnProperty(key)) {
            const value = this.qiyuParams[key];
            parmas += (`&${key}=${value}`);
          }
        }
      }

      const hasSearch = item.url.indexOf('?') !== -1;
      // 打开客服浮层
      // this.quyuURL = `https://app.xueersi.com/polymerh5/app/#/chat?sysNum=4a1c52959a974dd497260f4e0d50f1c9&channelFlag=${window.channelFlag || 2}${parmas}`;
      this.quyuURL = `${item.url}${hasSearch?'&':'?'}sysNum=4a1c52959a974dd497260f4e0d50f1c9&channelFlag=${window.channelFlag || 2}${parmas}`;

      window.console.log('客服链接==', this.quyuURL);

      if (item.url && this.quyuURL) {
        this.$nextTick(() => {
          const iframe = this.$refs.qiyuCustomer;
          if (iframe.attachEvent) { // ie
            iframe.attachEvent('onload', () => {
              this.quyuIframeShow = true;
              this.qiyuReady = true;
              window.addEventListener('message', (e) => {
                if (e.data.indexOf && e.data.indexOf('ACK') === 0) return;
                // 点击七鱼客服面板最小化按钮
                if (e && e.data) {
                  const data = this.parseJSON(e.data);
                  if (data && data.closeChat) {
                    this.quyuIframeShow = false;
                  }
                }
              }, false);
            });
          } else { // 其他浏览器
            iframe.onload = () => {
              this.quyuIframeShow = true;
              this.qiyuReady = true;
              window.addEventListener('message', (e) => {
                if (e.data.indexOf && e.data.indexOf('ACK') === 0) return;
                // 点击七鱼客服面板最小化按钮
                if (e && e.data) {
                  const data = this.parseJSON(e.data);
                  if (data && data.closeChat) {
                    this.quyuIframeShow = false;
                  }
                }
              }, false);
            };
          }
        });
      }
    },
    parseJSON(str) {
      try {
        const obj = JSON.parse(str);
        return obj;
      } catch (error) {
        return false;
      }
    },
    goTop() {
      window.scrollTo(0, 0);

      // 日志
      window.__XES_LOG__.clickMsg({
        params: {
          key: 'xes-mall-sidebar',
          clickid: '1.5',
        },
      });
    },
    clickFeedback(item) {
      if (!item.url) return
      // 日志
      window.__XES_LOG__.clickMsg({
        params: {
          key: 'xes-mall-sidebar',
          eventname: '反馈-点击',
          clickid: 'click_kyEW3YiN',// 1.4
        },
      });
      if (item.target === 2) {
        window.open(item.url);
      } else {
        window.location.href = item.url;
      }
    },

    clickJump(item) {
      if (!item.url) return
      if (+item.type === 6) {
        this.$xesLogger.clickMsg({
          params: {
            key: 'xes-mall-sidebar',
            eventname: '选课中心-点击',
            clickid: 'click_PPNirRI3'
          }
        })
      }
      if (item.target === 2) {
        window.open(item.url);
      } else {
        window.location.href = item.url;
      }
    },
    loadLogSDK() {
      return new Promise((resolve) => {
        const src = 'https://libs.xesimg.com/xes_fe_log/fe-logger-sdk.min.js';
        const logScript = document.createElement('script');
        logScript.src = src;
        document.body.appendChild(logScript);
        logScript.addEventListener('load', () => {
          new XesLoggerSDK({
            cacheUploader: {
              interval: 10000,
              batch: 5,
            },
            common: {
              eventid: 'mallwww',
            },
            network: {
              open: true,
              sample: 1,
            },
            clickMsg: {
              open: true,
            },
            loadMsg: {
              open: false, // 自动展现日志开关
            },
            runtime: {
              open: true,
              sample: 1,
            },
            resource: {
              open: true,
              sample: 1,
            },
            hashchange: {
              open: false, // hashchange自动展现日志开关
              pvid: 'hash',
              pageuid: { // 展现日志、交互日志pageuid路由映射
                index: 'pageindex' || 0, // 首页路由pageuid映射
              },
              // loadParam: {                     // 展现日志私有字段路由映射
              //     'index': {'key1': 'xxx'},    // 首页路由展现日志私有字段映射
              //     'path': {'key1': 'yyy'},
              //     'path/a': {'key1': 'zzz'}
              // }
            },
            performance: {
              open: true,
              sample: 1,
            },
            vue: {
              errorHandler: false,
            },
            trace: {
              open: true,
            },
            appid: {
              'iii.xueersi.com': {
                appid: '1004991',
              },
            },
            param: {
              key: '200021', // 展现日志、交互日志公共字段
            },
          });
          resolve(true);
        });
      });
    },
    handleGift(slideItem) {
      // window.open('https://online.xueersi.com/xes.php?source=249703443&site_id=1446&adsite_id=3564043')
      if (slideItem.target === 2) {
        window.open(slideItem.url)
      } else {
        window.location.href = slideItem.url
      }
      // 日志
      window.__XES_LOG__.clickMsg({
        params: {
          key: 'xes-mall-sidebar',
          clickid: '1.1',
        }
      })
    },
  },
};
</script>

<style lang="scss" scoped>
body,
button,
input,
select,
textarea,
div,
a,
span,
em,
i,
p,
h1,
h2,
h3,
h4,
h5,
h6,
dl,
dt,
dd {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  font-family: PingFangSC-Regular, PingFang SC, Microsoft YaHei, tahoma, arial,
  Hiragino Sans GB, sans-serif;
}

body {
  background-color: #f5f5f5;
  touch-action: auto;
}

input {
  -webkit-appearance: none;
}

a {
  text-decoration: none;
  outline: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

p {
  -webkit-margin-after: 0;
  -webkit-margin-before: 0;
}

div,
img {
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
}

i {
  font-style: normal;
}

.gift {
  box-sizing: border-box;
  width: 59px;
  height: 56px;
  display: block;
  color: #fff;
  text-align: center;
  position: absolute;
  top: -59px;
  right: 3px;
  background-image: url('./img/gift.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  animation: jump 0.6s infinite;
}

@keyframes jump {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0px);
  }
}

.slide-bar {
  position: fixed;
  width: 66px;
  right: 0;
  bottom: 10%;
  z-index: 999;
  background-color: #FFFFFF;
  box-shadow: 0px 2px 14px 3px rgba(0, 0, 0, 0.07);
  border-radius: 4px 0px 0px 4px;

  &__item {
    display: block;
    width: 66px;
    padding: 16px 0px 15px 0px;
    position: relative;
    text-align: center;
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;

    &:hover {
      background-color: #000000;
      color: #fff;

      .slide-bar__item__tips {
        visibility: visible;
        left: -140px;
        opacity: 1;
      }

      .slide-bar__item__scan {
        visibility: visible;
      }
    }

    &__img {
      position: relative;
      display: inline-block;
      height: 28px;
      width: 28px;
      text-align: center;
      background-repeat: no-repeat;
      background-size: 28px 28px;
      background-position: center;
    }

    &__top {
      display: block;
      line-height: 50px;
      font-size: 16px;
    }

    &__icon {
      display: block;
      line-height: 30px;
      font-size: 16px;
    }

    &__tips {
      position: absolute;
      width: 123px;
      height: 56px;
      overflow: hidden;
      line-height: 56px;
      text-align: center;
      font-size: 18px;
      font-weight: 400;
      border-radius: 4px;
      z-index: 1;
      color: #ffffff;
      top: 50%;
      left: -180px;
      transform: translateY(-50%);
      opacity: 0;
      visibility: hidden;
      background-color: #f13232;
      transition: left 0.3s ease-in-out 0.1s, opacity 0.3s ease-in-out 0.1s,
      visibility 0.3s;

      &::after {
        border-bottom: 5px solid transparent;
        border-top: 5px solid transparent;
        border-left: 6px solid #f13232;
        right: -6px;
        content: '';
        height: 0;
        position: absolute;
        top: 20px;
        width: 0;
        z-index: 1;
      }
    }
  }
}

.slide-bar__item__scan {
  box-sizing: border-box;
  position: absolute;
  display: block;
  visibility: hidden;
  width: 195px;
  height: 248px;
  background-color: #ffffff;
  border: 1px solid #E6E6E6;
  padding: 15px 0 20px;
  top: 50%;
  left: -206px;
  transform: translateY(-50%);
  z-index: 1;
  color: #E02727;
  font-size: 14px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: bold;

  &__text {
    line-height: 22px;
    padding: 0 12px;
  }
}

.goods-car {
  position: relative;
  background-image: url('./img/shopcart.png');

  &--hover {
    position: relative;
    background-image: url('./img/shopcart-hover.png');
  }

  &__count {
    box-sizing: border-box;
    position: absolute;
    min-width: 14px;
    width: auto;
    padding: 0px 5px;
    height: 14px;
    border-radius: 7px;
    background-color: #E02727;
    top: -1px;
    right: 0px;
    transform: translateX(50%);
    font-size: 10px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 14px;
  }
}

.scan {
  // background-image: url('./img/scan.png');
  background-color: var(--icon-color);
  // background: ~calc('url('+ var(--icon_img) +')');
  &:hover {
    //  background-image: url(var(--icon_img_hover))
  }

  &--hover {
    background-image: url('./img/scan-hover.png');
    // background-image: url(icon_img_hover)
  }
}

.zixun {
  background-image: url('./img/service.png');

  &--hover {
    background-image: url('./img/service-hover.png');
  }
}

.fankui {
  background-image: url('./img/feedback.png');

  &--hover {
    background-image: url('./img/feedback-hover.png');
  }
}

.arrow {
  background-image: url('./img/arrow.png');

  &--hover {
    background-image: url('./img/arrow-hover.png');
  }
}

.thin-line {
  display: block;
  margin: 0 auto;
  width: 42px;
  height: 1px;
  background: #999999;
  opacity: 0.1;
}

.qiyu-customer {
  position: fixed;
  width: 360px;
  height: 500px;
  background-color: #fff;
  bottom: 0;
  right: 20px;
  opacity: 1;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, .15);
  border-radius: 8px;
  z-index: 999999;
}

.xes-loading {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100px;
  height: 100px;
  background-image: url('./img/icon-loading-default.svg');
  background-size: 100px 100px;

  .xes-loading-tips {
    text-align: center;
    font-size: 14px;
  }
}
</style>
