<template>
<div>
  <div v-if="qwVisable">
    <div class="qw_modal"></div>
    <div id="img_text" class="img_text">
      <div class="img_text-container">
        <div id="img_close" class="img_close" @click="closeQWfn">
          <img src="./img/qw_close.png" height="100%" width="100%" alt="" srcset="">
        </div>
        <img id="qwImg" :src="imgSrc" width="100%" height="100%" alt="">
      </div>
    </div>
  </div>
</div>
</template>

<script>
import { requestNationalActionData } from '@request/nationalAction.js'
const OFF_CLOSE = 7;
export default {
  data() {
    return {
      imgSrc:'',
      qwVisable: false
    }
  },
  async mounted() {
    const tempResult = await requestNationalActionData()
    if(tempResult && !tempResult.result) {
      return
    }
    const {result:{data}} = tempResult
    this.imgSrc = data.show_img

    // 清网行动11
    // const time = new Date('2021-05-10 23:59:59')
    /*
    * 1. 点击关闭以后不再展示，清掉localStorage后再展示
    * 2. 当前时间  大于等于上线时间， 并且小于下线时间 —— 展示
    * 3. 当前时间  大于等于下线时间——不展示
    * 4. 上线时间===下线时间，不展示
    * */
    // 关闭以后，七天内不展示
    const closeTimeJSON = JSON.parse(localStorage.getItem('_xes_mall_qsVisable_closeTime'))

    if(closeTimeJSON != null){
      const now = new Date();
      // console.log(now, new Date(closeTimeJSON.offClose), now > new Date(closeTimeJSON.offClose),'======1');
      if(now > new Date(closeTimeJSON.offClose)) {
        localStorage.setItem('_xes_mall_qsVisable', '')
      }
    }

    const qwVisable = localStorage.getItem('_xes_mall_qsVisable')
    if(qwVisable === 'show' ||
      new Date(data.online_time) > new Date(data.now_time) || // 当前小于上线时间，不展示
      new Date(data.offline_time) <= new Date(data.now_time) ||  // 当前时间大于等于下线时间不展示
      new Date(data.offline_time) === new Date(data.online_time) ) { // 上线时间等于下线时间不展示
      this.qwVisable = false
      return
    } else if ( new Date(data.now_time) >= new Date(data.online_time) // 当前时间大于等于上线时间，展示
      && new Date(data.now_time) < new Date(data.offline_time)){ // 并且，当前时间小于下线时间时间，展示
      this.qwVisable = true
    }

    this.$nextTick(() => {
      this.resizeQWImg()
      window.addEventListener('resize', () => {
        console.log('window resize');
        this.resizeQWImg()
      })
    })
  },
  methods: {
    // requestNationalActionData,

    utc2beijing(utc_datetime){
      // 转为正常的时间格式 年-月-日 时:分:秒
      utc_datetime = utc_datetime.toString()
      console.log(utc_datetime.toString(), typeof utc_datetime, 'utc_datetime');
      let T_pos = utc_datetime.indexOf('T');
      let Z_pos = utc_datetime.indexOf('Z');
      if(T_pos === -1 || Z_pos === -1){
        return utc_datetime
      }
      let year_month_day = utc_datetime.substr(0,T_pos);
      let hour_minute_second = utc_datetime.substr(T_pos+1,Z_pos-T_pos-1);
      let new_datetime = year_month_day+" "+hour_minute_second; // 2017-03-31 08:02:06

      // 处理成为时间戳
      let timestamp = new Date(Date.parse(new_datetime));
      timestamp = timestamp.getTime();
      timestamp = timestamp/1000;

      // 增加8个小时，北京时间比utc时间多八个时区
      timestamp = timestamp+8*60*60;

      // 时间戳转为时间
      let beijing_datetime = new Date(parseInt(timestamp) * 1000).toLocaleString().replace(/年|月/g, "-").replace(/日/g, " ");
      return beijing_datetime; // 2017-03-31 16:02:06
    },
    resizeQWImg() {
      const height = window.innerHeight

      const resizeFn = () => {
        // 设置图片高度
        const img_text = document.getElementById('img_text')
        const width = height/(780/600)
        img_text.style.height = height + 'px'
        img_text.style.width =  width + 'px'
        img_text.style.top = `calc(50% - ${height/2}px)`
        img_text.style.left = `calc(50% - ${width/2}px)`

        const img_close = document.getElementById('img_close')
        const img_text_height = height/(780/25)
        img_close.style.height = img_text_height + 'px'
        img_close.style.width = img_text_height+ 'px'
        img_close.style.top = `${img_text_height}px`
        img_close.style.right = `${img_text_height}px`
      }

      if(height < 780) {
        resizeFn()
      } else {
        const imgDom = document.getElementById('qwImg')
        const imgHeight = imgDom.clientHeight * 0.85
        if (imgHeight < 620) {
          resizeFn()
        }
      }
    },
    closeQWfn() {
      this.qwVisable = false
      localStorage.setItem('_xes_mall_qsVisable', 'show')
      /*
      * 用户每周，首次打开，弹出弹窗
      * 临界点：「关闭」后，7天内不弹出了
      * */
      let onClose = new Date(); // 关闭的时间点
      // 临界点：精确到时分秒
      let offClose = new Date(onClose.getFullYear(), onClose.getMonth(), onClose.getDate()+OFF_CLOSE, onClose.getHours(), onClose.getMinutes(), onClose.getSeconds());

      onClose = this.utc2beijing(onClose)
      offClose = this.utc2beijing(offClose)

      // console.log(onClose, offClose, '===onClose, offClose===');

      localStorage.setItem('_xes_mall_qsVisable_closeTime', JSON.stringify({
        onClose,
        offClose
      }))
    },
  }
}
</script>

<style>
.qw_modal {
  z-index: 10000;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: #000;
  opacity: 0.7;
}

.img_text{
  position: fixed;
  left: calc(50% - 300px);
  top: calc(50% - 380px);
  opacity: 1;
  z-index: 10001;
  height: 780px;
  width: 600px;
}
.img_text-container{
  position: absolute;
  z-index: 10001;
  width: 100%;
  height: 100%;
  transform: scale(0.85);
}

.img_close {
  position: absolute;
  right: 25px;
  top: 25px;
  height: 25px;
  width: 25px;
  z-index: 10003;
}
#qwImg {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10002;
}
</style>
