<template>
  <div v-if="topAdvert">
      <!-- 纯文本顶部广告 -->
      <div @click="handleClick" class="top-adv" ref="topAdv" v-if="topAdvert && topAdvert.content && computedType" 
        v-show="noExp" v-html="topAdvert && topAdvert.content"></div>
      <!-- 图片顶部广告 -->
      <div class="top-adv" ref="topAdv" v-else-if="Number(topAdvert.type) === 1" @click="handleClick">
        <div class="xue-mall-advertise-pic" id="xue-mall-advertise-pic">
          <a class="xue-mall-header-advertise-link"
            :href="topAdvert.content.url"
            :style="{ backgroundImage: `url(${topAdvert.content.img_url})` }"
            :title="topAdvert.content.title"
          ></a>
          <div class="xue-mall-advertise-box container"><span ref="closeBtn" class="xue-mall-advertise-closeBtn"></span></div>
        </div>
    </div>
  </div>
</template>

<script>
import { getCookies } from '@utils/js-cookie.js'
import requestAdvsData from '@request/ads.js'

export default {
  name: 'top-adv',
  props: ['adParams'],
  data() {
    return {
      ready: false,
      timer: null,
      close: true,
      timeflag: true,
      clickURL: null,
      noExp: false,
      countTime: {
        text: '',
        time: ''
      },
      topAdvData: null
    }
  },
  mounted() {
    this.ready = true
  },
  destroyed() {
    clearInterval(this.timer)
  },
  computed: {
    // 取广告数据中展示的部分
    topAdvert() {
      if (!this.topAdvData) return false
      
      if (!this.topAdvData.adverts.length) return false
      
      return this.topAdvData.adverts[0]
    },
    computedType() {
      if (!this.topAdvData) return false
      
      if (!this.topAdvData.adverts.length) return false

      const type = Number(this.topAdvert.type)

      if (type === 2 || type === 5) { return true }

      return false
    }
  },
  watch: {
    adParams: {
      immediate: true,
      async handler () {
        if (!this.adParams) return
        if (this.adParams.similarId && this.adParams.provinceId) {
          this.topAdvData = await this.requestAdvsData(this.adParams.similarId, this.adParams.provinceId, 1)
        }
      }
    },
    topAdvert() {
      this.ready && this.$nextTick(() => this.init())
    }
  },
  methods: {
    requestAdvsData,
    // 初始化定时等数据
    init() {
      if (!this.computedType) {
        this.clickURL = document.getElementsByClassName('xue-mall-header-advertise-link')[0].href
      }
      this.xesLog('exposure', 'xeslog-top-adv-exposure', 'a')

      // 纯文本顶部广告中数据的html文本中，携带有settime类
      const settime = document.getElementsByClassName('settime')[0]
      if (settime) {
        // 判断广告是否带有倒计时
        this.setTimer()
      } else {
        this.noExp = true
      }

      if (this.$refs.closeBtn) {
        document.getElementsByClassName('xue-mall-advertise-closeBtn')[0].addEventListener('click', e => {
            e.stopPropagation()
            this.xesLog('click', 'xeslog-top-adv-close-click', '1.1.31', 'b')
            clearInterval(this.timer)
            this.$refs.topAdv.parentElement.removeChild(this.$refs.topAdv)
          },
          false
        )
      }
    },

    setTimer() {
      this.timer = setInterval(this.countDown, 100)
    },

    countDown() {
      const now = new Date().getTime()
      const endTime = document.getElementsByClassName('settime')[0].getAttribute('endtime') // 获取截止时间
      const haltSaleTime = endTime && new Date(endTime).getTime()
      if (haltSaleTime && haltSaleTime > now) {
        this.noExp = true
        this.countTime.time = this.calculateTime(now, haltSaleTime)
        document.getElementsByClassName('settime')[0].innerHTML = this.countTime.time
        return
      }
      this.noExp = false
      clearInterval(this.timer)
    },
    
    calculateTime(start, end) {
      let remaining = 0
      const intervalSecond = (end - start) / 1000
      const days = Math.floor(intervalSecond / (60 * 60 * 24))
      remaining = intervalSecond % 86400
      const hours = Math.floor(remaining / 3600)
      remaining = remaining % 3600
      const minute = Math.floor(remaining / 60)
      remaining = remaining % 60
      const second = remaining.toFixed(1)
      return ` ${days}天 ${hours}时${minute}分${second}秒`
    },

    handleClick() {
      this.xesLog('click', 'xeslog-top-adv-click', '1.1.15', 'b')
    },

    xesLog(eventName, key, clickId, logType) {
      const deviceId = window.plus ? window.plus.device.imei : getCookies('xesId') || ''
      const item = this.topAdvData.adverts[0]
      const advertInfo = {
        dnf: this.topAdvData.dnf,
        extra: this.topAdvData.extra,
        order: item.order,
        ad_id: item.id,
        material_id: Number(item.material_id),
        grade_id: Number(this.topAdvData.dnf.grade)
      }

      this.$xesLogger.cacheUploader({params: {
        key,
        clickid: clickId,
        log_from: 'advert',
        clickurl: this.clickURL,
        device_id: deviceId,
        client_type: 'pc',
        event_name: eventName,
        advert_info: advertInfo
      }}, logType, 'pv'); 
    }
  }
}
</script>

<style lang="scss">
.top-adv {
  min-width: 1200px;
  clear: both;
  position: relative;
  a {
    height: 80px;
    width: 100%;
    background-repeat: no-repeat !important;
    background-position: top center !important;
    display: block;
    overflow: hidden;
    text-align: center;
  }
}
.xue-mall-advertise-pic .xue-mall-advertise-box {
  position: absolute;
  top: 0;
  left: 50%;
  margin-left: -600px;
}
.xue-mall-advertise-pic .xue-mall-advertise-box .xue-mall-advertise-closeBtn {
  cursor: pointer;
  position: absolute;
  right: 20px;
  top: 8px;
  font-weight: bold;
  font-size: 14px;
  color: #fff;
  width: 20px;
  text-align: center;
  line-height: 20px;
  height: 20px;
  background-image: url(./advertise_closeBtn.png);
  background-size: cover;
}
.container {
  width: 1200px !important;
  margin: 0 auto;
  padding: 0;
  &::before {
    content: ' ';
    display: table;
  }
  &::after {
    content: ' ';
    display: table;
  }
}
.timeCountDown {
  display: inline-block;
  padding: 0 10px;
  height: 38px;
  background: #ff3334;
  text-align: center;
  color: #fff;
  font-size: 16px;
  margin-top: 0;
  line-height: 38px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}
</style>
