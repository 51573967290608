import jsCookie from 'js-cookie'
import request from '@request'
const isProduction = process.env.NODE_ENV === 'production'

const baseUrl = isProduction ? 'https://mall.xueersi.com/' : '/mall-proxy/'

export async function requestNationalActionData () {
  const {userGrade=1, stu_area_id=100} = jsCookie.get()
  try {
    const res = await request.post(baseUrl + 'clearaction/content', {
      'region':Number(stu_area_id),
      'grade':Number(userGrade),
      'device': 3 /* 设备(1=>'Pc';2=>'Touch';3=>'Web') */
    })
    if (res && res.result && res.result.status === 1) {
      return res;
    } else {
      return {}
    }

  } catch (error) {
    console.log(error)
    return {}

  }
}

