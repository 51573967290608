if ('serviceWorker' in navigator) {
  // eslint-disable-next-line no-debugger
  window.addEventListener('load', function () {
    navigator.serviceWorker.register('/public/js/serviceWorker.js')
      .then(function (registration) {
        // 注册成功
        // eslint-disable-next-line no-debugger
        // console.log('ServiceWorker registration successful with scope: ', registration.scope);
      })
      .catch(function (err) {
        // 注册失败
        // eslint-disable-next-line no-debugger
        // console.log('ServiceWorker registration failed: ', err);
      });
  });
}