<template>
  <div class="app">
    <template v-if="$route.name && activeIndex !== 1">
      <top-adv :adParams="adParams" />
      <navigator
        :activeIndex="1"
        :bs-env="10"
        :isShowSlideBar="isShowSlideBar"
        :debugger="isDebugger"
        :pageType="activeIndex"
      />
    </template>
    <!-- <xes-mall-sidebar /> -->
    <div class="router-box">
      <router-view></router-view>
    </div>
    <globalFooter v-if="showFooter" :feedBackUrl="feedBackUrl" />
    <national-action />
  </div>
</template>

<script>
import topAdv from "@components/top-adv";
import Cookies from "js-cookie";
// import xesMallSidebar from "../mall-www-source/src/components/xesMallSidebar";
import navigator from "../mall-www-source/src/components/navigator";
import nationalAction from "@components/nationalAction/index.vue";
import { globalFooter } from "@xes/xueersi-mall-components";
import request from "@request";

const db = window.localStorage;

export default {
  name: "app",
  components: {
    topAdv,
    navigator,
    // xesMallSidebar,
    nationalAction,
    globalFooter,
  },
  data() {
    return {
      showFooter: false,
      feedBackUrl: "",
      token: "",
      showTopAdv: false,
      // 广告需要的参数
      adParams: null,
      isDev: process.env.NODE_ENV === "development",
      isDebugger: process.env.NODE_ENV === "development", // process.env.NODE_ENV === 'local' false
    };
  },
  created() {
    this.$on("onData", (data) => {
      this.adParams = data;
    });
    this.requestTokenDataFn();
  },
  mounted() {
    this.$eventBus.$on("RouterPageMounted", (routerName) => {
      this.showFooter = true;
    });

    this.handleIframeUrl();
  },
  computed: {
    splitShadow() {
      const routeName = this.$route.name;
      return routeName === "select-course";
    },
    activeIndex() {
      const routeName = this.$route.name;
      if (
        routeName === "index" ||
        routeName === "about" ||
        routeName === "humanities" ||
        routeName === "code" ||
        routeName === "science" ||
        routeName === "eloquence" ||
        routeName === "naturalScience" ||
        routeName === "create" ||
        routeName === "life" ||
        routeName === "art" ||
        routeName === "chess" ||
        routeName === "box"
      ) {
        return 1;
      } else if (routeName === "download") {
        return 3;
      }
      return 2;
    },
  },
  methods: {
    handleIframeUrl() {
      const stuId = Cookies.get("stu_id");
      if (stuId) {
        this.feedBackUrl = `https://app.xueersi.com/servicepc/#/feedback?channelId=1&businessId=1&subBusinessId=1&roleId=4&userId=${stuId}&sourceFrom=xeswxpc`;
      } else {
        this.feedBackUrl =
          "https://app.xueersi.com/servicepc/#/feedback?channelId=1&businessId=1&subBusinessId=1&roleId=4&sourceFrom=xeswxpc";
      }
    },

    async requestTokenDataFn() {
      const tokenData = db.getItem("__xes_login_data__");
      if (
        tokenData &&
        JSON.parse(tokenData).exp > Math.ceil(Date.now() / 1000)
      ) {
        this.token = JSON.parse(tokenData).token;
        this.$global.isLogin = true;
        this.$eventBus.$emit("isLoginChange", true);
      } else {
        const _this = this;

        function handleError() {
          db.removeItem("__xes_login_data__");
          _this.$global.isLogin = false;
          _this.$eventBus.$emit("isLoginChange", false);
        }

        try {
          const res = await request.get(
            "https://login.xueersi.com/auth/getAccessTokenNew"
          );
          const { body: token, exp, status } = res;
          // status 9表示未登录的状态 顶替了原来的401状态返回
          if (Number(status) === 9) {
            handleError();
          } else {
            this.token = token;
            db.setItem("__xes_login_data__", JSON.stringify({ token, exp }));
            this.$global.isLogin = true;
            this.$eventBus.$emit("isLoginChange", true);
          }
        } catch (error) {
          console.log(error);
        }
      }
    },
  },
};
</script>

<style lang="scss">
body {
  font-family: "PingFangSC-Regular", "Microsoft YaHei", "WenQuanYi Micro Hei",
  "Helvetica Neue", Helvetica, Arial, "Hiragino Sans GB",
  "Hiragino Sans GB W3", sans-serif;
}

@font-face {
  font-family: "PingFangSC-Regular";
  font-weight: 400;
  font-display: swap;
  src: url("https://static0.xesimg.com/platform-fe/fonts/PingFang_Regular.eot?#iefix");
  src: url("https://static0.xesimg.com/platform-fe/fonts/PingFang_Regular.eot?#iefix") format("embedded-opentype"),
  url("https://static0.xesimg.com/platform-fe/fonts/PingFang_Regular.woff") format("woff"),
  url("https://static0.xesimg.com/platform-fe/fonts/PingFang_Regular.ttf") format("ttf");
}

.app {
  width: 100%;
  //height: 100vh;
  min-height: 100%;
  background-color: #f5f5f5;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;

  .user__dropmenu {
    z-index: 10;
  }
}

.app .router-box {
  min-height: calc(100vh - 215px);
  flex-grow: 1;
}

.noBoxShowad {
  .pc_header-fixed {
    box-shadow: none !important;
  }
}

.hasBoxShowad {
  .pc_header-fixed {
    box-shadow: 2px 2px 6px 0px rgba(204, 204, 204, 0.5) !important;
  }
}

.hidden {
  overflow: hidden;
}

.dialog-fade-enter-active {
  animation: dialog-fade-in 0.3s;
}

.dialog-fade-leave-active {
  animation: dialog-fade-out 0.3s;
}

.fade-enter-active {
  animation: dialog-fade-in 0.3s;
}

.fade-leave-active {
  animation: dialog-fade-out 0.3s;
}

@keyframes dialog-fade-in {
  0% {
    transform: translate3d(0, -20px, 0);
    opacity: 0;
  }
  100% {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

@keyframes tds-keyframe--bounce {
  0%,
  20%,
  50%,
  80%,
  to {
    transform: translateY(0);
  }

  40% {
    transform: translateY(5px);
  }

  60% {
    transform: translateY(3px);
  }
}

@keyframes dialog-fade-out {
  0% {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
  100% {
    transform: translate3d(0, -20px, 0);
    opacity: 0;
  }
}
</style>
