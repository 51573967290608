import request from "@request";
import jsCookie from "js-cookie";
import { apiBaseURL } from "./_base";
// 请求焦点图数据
//【注意，广告接口不隶属商城接口（mall域名下为商城接口， 所以需要传年级的similarId，其他不隶属商城的接口也是如此）】
// adslot_id [Number] 广告类型id，1: pc首页顶部广告图； 2: pc首页轮播图广告，4:pc首页遮罩广告；5：pc首页底部广告图
export default async function requestAdvsData(
  similarId,
  provinceId,
  adslot_id = 2
) {
  if (!similarId) return;
  const cookies = jsCookie.get();
  if (adslot_id === 4 && cookies.hideShadeAdv) {
    return;
  }
  try {
    const res = await request.post(`${apiBaseURL}slogan/v1/search`, {
      adslot_id: adslot_id,
      user_id: cookies.stu_id * 1 || 0,
      info: {
        grade: similarId * 1,
        area: provinceId
      }
    });
    if (res && res.stat === 1) {
      if (adslot_id < 6 && adslot_id > 0) {
        return res.data;
      } else {
        throw new Error(`unkown adslot_id ${adslot_id} type, please check!`);
      }
    } else {
      return [];
    }
  } catch (error) {
    console.log(error);
  }
}
