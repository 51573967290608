<template>
<div class="newer-guide" v-if="showGuide && show">
  <img class="newer-guide__img" :src="hasLogin ? require('../img/login-321c8.png') : require('../img/login-321c8.png')" alt="新手指南">
  <div class="newer-guide__close" @click="closeGuide"></div>
</div>
</template>

<script>
import { getCookies, setCookies } from '../../../util/js-cookie'
export default {
  name: 'newer-guide',
  props: {
    hasLogin: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      show: false,
      showGuide: false
    }
  },
  created() {
    this.show = true
    this.showGuide = !this.getCookies('www-guide')
    if (this.showGuide) {
      this.$emit('noShowDrop', true)
    }
  },
  async mounted() {
    // 加载新版日志
    if (!window.__XES_LOG__) {
      await this.loadLogSDK()
    }

    window.__XES_LOG__.loadMsg({key: 'xes-mall-userguide'})
  },
  methods: {
    getCookies,
    closeGuide () {
      window.__XES_LOG__.clickMsg({
        params: {
          key: 'xes-mall-userguide',
          clickid: `2.0`,
        }
      })
      this.show = false
      setCookies('www-guide', true, 3 * 365)
      this.showGuide = true
      this.$emit('noShowDrop', false)
    },
    loadLogSDK () {
      return new Promise((resolve) => {
        const src = 'https://libs.xesimg.com/xes_fe_log/fe-logger-sdk.min.js'
        const logScript = document.createElement('script')
        logScript.src = src
        document.body.appendChild(logScript)
        logScript.addEventListener('load', () => {
          new XesLoggerSDK({
            cacheUploader: {
                interval: 10000,
                batch: 5,
            },
            common: {
                eventid: 'mallwww',
            },
            appid: {
                'iii.xueersi.com': {
                    'appid': '1004991'
                }
            }
          })
          resolve(true)
        })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.newer-guide{
  position: relative;
  &__img{
    display: block;
    position: absolute;
    top: 5px;
    z-index: 1111;
    right: -16px;
    width: 274px;
    height: 347px;
  }
  &__close{
    display: block;
    position: absolute;
    top: 36px;
    right: 12px;
    width: 14px;
    height: 14px;
    z-index: 1200;
    background-image: url('./close-btn.png');
    background-size: cover;
    cursor: pointer;
  }
}
</style>
