<template>
  <div>
    <!-- 固定头部式 -->
    <template>
      <nav-com
          :activeIndex="activeIndex"
          :bsenv="bsenv"
          :isLogin="isLogin"
          :userinfo="userinfo"
          :is-show-login="isShowLogin"
          :drop-list="dropList"
          :page-type="pageType"
          :child-page-type="childPageType"
          :custom-cart-count="customCartCount"
          :nav-list="navList"
          :transParent="immersive"
          :debugger="isDebugger"
          :isShowSlideBar="isShowSlideBar"
      />
    </template>
    <!-- 沉浸式 -->
    <!--    <template v-else>
          &lt;!&ndash; 嵌入部分 &ndash;&gt;
          <div class="pc_header">
            <nav-com
              :activeIndex="activeIndex"
              :bsenv="bsenv"
              :isLogin="isLogin"
              :userinfo="userinfo"
              :is-show-login="isShowLogin"
              :drop-list="dropList"
              :page-type="pageType"
              :nav-list="navList"
              :custom-cart-count="customCartCount"
              :debugger="isDebugger"
              :transParent="immersive"
            />
          </div>
          &lt;!&ndash; 上拉时滑出部分 &ndash;&gt;
          <transition name="slide-in">
            <div v-show="isScroll" class="pc_header scrolling">
              <nav-com
                :activeIndex="activeIndex"
                :bsenv="bsenv"
                :isLogin="isLogin"
                :userinfo="userinfo"
                :is-show-login="isShowLogin"
                :page-type="pageType"
                :drop-list="dropList"
                :custom-cart-count="customCartCount"
                :is-show-slide-bar="false"
                :nav-list="navList"
                :debugger="isDebugger"
              />
            </div>
          </transition>
        </template>-->
    <!-- 非沉浸式时的占位高度， 防止页面内容被遮挡 -->
    <!--    <div class="occupy_box" v-if="!immersive"></div>-->
  </div>
</template>

<script>
import NavCom from './nav.vue'
export default {
  name: 'navigator',
  components: {
    NavCom
  },
  props: {
    // 是否沉浸式
    immersive: {
      type: Boolean,
      default: false
    },
    // 当前活动的nav选项
    activeIndex: {
      type: Number,
      default: 1
    },
    isScroll: {
      type: Boolean,
      default: false
    },
    isLogin: {
      type: Boolean,
      default: false
    },
    // 手动传入isLogin=true时，需要传入用户信息
    userinfo: {
      type: Object,
      default: () => null
    },
    navList: {
      type: Array,
      default: () => []
    },
    dropList: {
      type: Array,
      default: () => []
    },
    // 业务id，默认素质
    bsenv: {
      type: Number,
      default: 10
    },
    // 是否开发环境调试
    debugger: {
      type: Boolean,
      default: true
    },
    // 是否展示slideBar
    isShowSlideBar: {
      type: Boolean,
      default: true,
    },
    // 是否显示登录按钮
    isShowLogin: {
      type: Boolean,
      default: true,
    },
    // 标识页面类型 1：首页，2：选课中心，3：下载客户端，4：原希望在线
    pageType: {
      type: Number,
      default: 1
    },
    childPageType: {
      type: Number,
    },
    // 购物车角标数量
    customCartCount: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      isDebugger: this.debugger
    }
  }
}
</script>

<style lang="scss" scoped>
.nav-com {
  height: 94px; // 导航占位
}

.slide-in-enter-active {
  transition: all 0.25s ease;
}
.slide-in-leave-active{
  transition: all 0.2s ease;
}
// in vue3
// .slide-in-enter-from
.slide-in-enter, .slide-in-leave-to {
  transform: translate3d(0, -100%, 0);
}

.pc_header{
  width: 100vw;
  background-color: transparent;
  &-fixed{
    position: fixed;
    top: 0;
    z-index: 100;
    box-shadow: 2px 2px 6px 0px rgba(204, 204, 204, 0.5);
    background-color: #fff;
  }
}
.scrolling{
  position: fixed;
  top: 0;
  box-shadow: 2px 2px 6px 0px rgba(204, 204, 204, 0.5);
  background-color: #fff;
  z-index: 999;
}
.occupy_box{
  height: 94px;
}
</style>
