export function setCookies (name, value, day) {
  var d = new Date()
  var expires = ''
  if (day) {
    d.setTime(d.getTime() + (day * 24 * 60 * 60 * 1000))
    expires = 'expires=' + d.toUTCString()
  }
  document.cookie = name + '=' + value + ';' + expires
}
export function getCookies (name) {
  var arr
  var reg = new RegExp('(^| )' + name + '=([^;]*)(;|$)')
  if (arr === document.cookie.match(reg)) {
    return  arr&&arr[2]&&unescape(arr[2])
  } else {
    return null
  }
}
export function delCookies (name) {
  var del = new Promise(function (resolve, reject) {
    if (name) {
      var exp = new Date()
      exp.setTime(exp.getTime() - 1)
      var cval = genral.getCookies(name)
      if (cval != null) {
        document.cookie = name + '=' + cval + ';expires=' + exp.toGMTString()
      }
    } else {
      var keys = document.cookie.match(/[^ =;]+(?=)/g)
      if (keys) {
        for (var i = keys.length; i--;) {
          document.cookie = keys[i] + '=0;expires=' + new Date(0).toUTCString()
        }
      }
    }
    resolve()
  })
  return del
}