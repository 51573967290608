function envConfigFn(env) {
  let LOGO_PATH_DEFAULT, LOGO_PATH_WHITE, DEFAULT_GREADEID
  let LOGIN_JUMP_BASE_URL
  let LOGOUT_BASE_URL
  let COUSE_HOME_PATH = ''
  let MALL_API_BASE = ''
  let EVENTID = ''
  let LOGIN_API_BASE = ''
  let PAGE_DOMAIN = ''
  const LOG = {}
  switch (env) {
    case 10:
      LOGO_PATH_DEFAULT = require('./img/suzhi-silogan.png')
      LOGO_PATH_WHITE =  require('./img/suzhi-silogan-transp.png')
      DEFAULT_GREADEID = 8
      LOGIN_JUMP_BASE_URL = 'https://login.xueersi.com/'
      LOGOUT_BASE_URL = 'https://login.xueersi.com/newLogin/logout/'
      COUSE_HOME_PATH = 'https://online.xueersi.com/select-course'
      PAGE_DOMAIN = 'online.xueersi.com'
      EVENTID = 'mallwww'
      LOG.appid = '1000012'
      LOG.appkey = '651549f8600025626961a0eb91dcd860'
      MALL_API_BASE = 'https://mall.xueersi.com/'
      LOGIN_API_BASE = 'https://login.xueersi.com/'
      break;
    case 20: 
      LOGO_PATH_DEFAULT = require('./img/gaozhong-silogan.png')
      LOGO_PATH_WHITE = require('./img/gaozhong-silogan-transp.png')
      DEFAULT_GREADEID = 11
      LOGIN_JUMP_BASE_URL = 'https://www.vipx.com/api/login/Page/index'
      LOGOUT_BASE_URL = 'https://www.vipx.com/api/login/newLogin/logout/'
      COUSE_HOME_PATH = 'https://gapzhong.xueersi.com/page/mall/pc-select'
      PAGE_DOMAIN = 'www.vipx.com'
      EVENTID = 'xesgaozhong'
      LOG.appid = '1004581'
      LOG.appkey = 'd63639b8d596274665ba80df78fc5a3a'
      MALL_API_BASE = 'https://mall.xueersi.com/'
      LOGIN_API_BASE = 'https://login.xueersi.com/'
      break
    case 30:
      LOGO_PATH_DEFAULT = require('./img/xiwang-silogan.png')
      LOGO_PATH_WHITE = require('./img/xiwang-silogan-transp.png')
      DEFAULT_GREADEID = 2
      LOGIN_JUMP_BASE_URL = 'https://www.xiwang.com/api/login/Page/index'
      LOGOUT_BASE_URL = 'https://www.xiwang.com/api/login/newLogin/logout/'
      COUSE_HOME_PATH = 'https://www.xiwang.com/page/mall/pc-select'
      PAGE_DOMAIN = 'www.xiwang.com'
      EVENTID = 'xesxinxueke'
      LOG.appid = '1004486'
      LOG.appkey = '3b183ec9225e2ee82963d488ee49e42b'
      MALL_API_BASE = 'https://api.xiwang.com/mall/'
      LOGIN_API_BASE = 'https://api.xiwang.com/login/'
      break
    default:
      break;
  }  
  return {
    LOGO_PATH_DEFAULT,
    LOGO_PATH_WHITE,
    DEFAULT_GREADEID,
    LOGIN_JUMP_BASE_URL,
    LOGOUT_BASE_URL,
    COUSE_HOME_PATH,
    PAGE_DOMAIN,
    EVENTID,
    LOG,
    MALL_API_BASE,
    LOGIN_API_BASE
  }
}

export default envConfigFn


