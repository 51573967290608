<template>
  <div :class="navClass.nav_container">
    <div :class="navClass.header_fixed">
      <div :class="['pc_header-content']">
        <!-- logo -->
        <img
          v-if="envConfig"
          :src="envConfig.LOGO_PATH_WHITE"
          class="pc_header-content-logo"
          @click="toHome"
        />
        <div class="pc_header-content-right">
          <!-- 导航列表 -->
          <template v-if="activeNavData.length">
            <div
              v-for="(navItem, navIndex) in activeNavData"
              :key="navIndex"
              :class="[
              'page_links',
              navClass.page_links
            ]"
              @click="clickNavLink(navItem.url, navItem.target === 2, navItem.type)"
            >
              <div :class="[curPosition === navItem.type && `active_link ${navClass.active_link}`]">
                {{ navItem.name }}
              </div>

              <!-- 子导航 -->
              <template v-if="navItem.child && navItem.child.length > 0">
                <img
                  :src="navClass.arrowDown"
                  alt="arrow"
                  class="page_links-arrow"
                />
              </template>
              <div v-if="navItem.child && navItem.child.length > 0" class="user__dropmenu">
                <div class="user__dropmenu-block">
                  <a
                    v-for="(childItem, childIndex) in navItem.child"
                    :key="'childItem' + childIndex"
                    :class="{active: childItem.type === childPageType}"
                    class="menu__item"
                    @click.stop="clickNavLink(childItem.url, childItem.target === 2, navItem.type, childItem.type)"
                  >
                    {{ childItem.name }}
                  </a>
                </div>
              </div>
            </div>
          </template>
          <template v-if="isShowLoginBtn && navData.length">
            <!-- 用户信息、操作列表 -->
            <div v-if="checking" class="checking">
              <img :src="navClass.iconLoading" alt="loading" />
            </div>
            <template v-else>
              <div v-if="checkLogin" class="user_btn">
              <span
                :style="{
                  'background-image': `url(${userData && userData.avatar_path})`
                }"
                class="user_btn-avatar"
              />
                <span :class="['user_btn-name', navClass.btn_name]">{{
                    (userData && (userData.realname || userData.nickname)) || ''
                  }}</span>
                <img
                  :src="navClass.arrowDown"
                  alt="arrow"
                  class="user_btn-arrow"
                />
                <div v-if="!cantDrop" class="user__dropmenu">
                  <div class="user__dropmenu-block">
                    <!-- 用户中心操作列表 -->
                    <a
                      v-for="(item, index) in navLoginList.length && navLoginList"
                      :key="item.url.toString() + index"
                      :href="item.url"
                      :target="item.target === 2 ? '_blank' : 'self'"
                      class="menu__item"
                      @click="item.order === 1 &&  handleDropItem()"
                    >
                      {{ item.name }}
                    </a>
                    <!-- 至少存在退出登录   -->
                    <a class="menu__item" @click="clickToLogoutFn">退出登录</a>
                  </div>
                </div>
              </div>
              <div
                v-else
                :class="['login_btn']"
                @click="toLogin"
                @mouseenter="hoverUserName"
                @mouseleave="outUserName"
              >
                登录/注册
              </div>
              <guide v-if="showGuide" :hasLogin="checkLogin" @noShowDrop="noShowDrop" />
            </template>
          </template>
        </div>
      </div>
      <div v-show="isShowSlideBar" class="pc_slide_bar">
        <sidebar
          :bsenv="bsenv"
          :isShowSlideBar="isShowSlideBar"
          :customCartCount="customCartCount"
          :hasLogin="isLogin"
          :sideList="slideList"
        >
        </sidebar>
      </div>
    </div>
  </div>
</template>

<script>
import Cookies from 'js-cookie'
import axios from 'axios'
import envConfigFn from './env'
import sidebar from '../sidebar'
import guide from './guide'
import { mallBaseURL, loginBaseURL } from '../../networks/_base'
import { xAreaIdEnum } from './XAreaId.js'
import arrowDown from './img/arrow-down.png'
import arrowDownWhite from './img/arrow-down-white.png'
import iconLoading from './img/icon_loading.png'
import iconLoadingWhite from './img/icon_loading_white.png'

export default {
  name: 'NavCom',
  components: {
    sidebar,
    guide
  },
  props: {
    transParent: {
      // 是否透明，沉浸式时透明
      type: Boolean,
      default: false
    },
    isLogin: {
      type: Boolean,
      default: false
    },
    // 手动传入isLogin=true时，需要传入用户信息
    userinfo: {
      type: Object,
      default: () => null
    },
    navList: {
      type: Array,
      default: () => MockData
    },
    childPageType: {
      type: Number,
    },
    // 当前活动的nav选项
    activeIndex: {
      type: Number,
      default: 1
    },
    // 用户中心下拉列表
    dropList: {
      type: Array,
      default: () => []
    },
    // 业务id，默认素质
    bsenv: {
      type: Number,
      default: 10
    },
    // 是否开发环境调试
    debugger: {
      type: Boolean,
      default: false
    },
    // 是否展示slideBar
    isShowSlideBar: {
      type: Boolean,
      default: true
    },
    // 购物车数量
    customCartCount: {
      type: String,
      default: ''
    },
    // 是否显示登录按钮
    isShowLogin: {
      type: Boolean,
      default: false
    },
    // 标识页面类型 1：首页，2：选课中心，3：下载客户端，4：原希望在线
    pageType: {
      type: Number,
      default: 0
    }
  },
  data() {
    let immersiveNavClass = {
      nav_container: '',
      page_links: 'immersive_page_links',
      btn_name: 'immersive_btn_name',
      active_link: 'immersive_active_link',
      header_fixed: 'immersive_header_fixed',
      arrowDown: arrowDownWhite,
      iconLoading: iconLoadingWhite
    }
    let noImmersiveNavClass = {
      nav_container: 'no_immersive_nav_container',
      page_links: 'no_immersive_page_links',
      btn_name: 'no_immersive_btn_name',
      active_link: 'no_immersive_active_link',
      header_fixed: 'no_immersive_header_fixed',
      arrowDown: arrowDown,
      iconLoading: iconLoading
    }

    return {
      // 根据transParent判断是否为沉浸式
      // 应包含沉浸式/非沉浸式的(滑动和非滑动)背景及选中态
      navClass: this.transParent ? immersiveNavClass : noImmersiveNavClass,
      envConfig: null,
      userGradeId: 8,
      checkLogin: this.isLogin,
      userData: this.userinfo,
      navData: [],
      activeNavData: [],
      checking: true, // 是否检查登录态中
      slideList: [],
      navLoginList: [],
      showGuide: false,
      showDropList: false,
      cantDrop: false,
      isShowLoginBtn: this.isShowLogin, // 是否显示登录按钮
      curPosition: 1,
      isDebugger: this.debugger || process.env.NODE_ENV === 'local'
    }
  },
  computed: {
    activeChildName() {
      let childPageName = ''
      if (this.childPageType) {
        this.activeNavData.forEach(navItem => {
          navItem.child && navItem.child.forEach(childItem => {
            if (childItem.type === this.childPageType) {
              childPageName = childItem.name
            }
          })
        })
      }
      return childPageName
    }
  },
  async beforeMount() {
    this.showGuide = this.$route.name === 'index'
    this.envConfig = envConfigFn(this.bsenv)
    // 先设置个默认年级
    this.userGradeId = this.envConfig.DEFAULT_GREADEID
    const getLocalPos = JSON.parse(localStorage.getItem('XESNAVTYPE'))
    if (getLocalPos) {
      this.curPosition = Number(getLocalPos[this.pageType]) || this.pageType
    } else {
      this.curPosition = this.activeIndex
    }
    // 如果手动传入了isLogin：true，意味着登录时业务方自己控制，不走默认接口检查
    // transParent 沉浸式导航只调用一次接口
    if (!this.checkLogin) {
      await this.requestUserDataFn()
      this.checking = false
      // 如果传入了navList不为空数组，则意味着整个导航列表都是传参的方式配置，不走默认接口
      if (!this.navData.length) {
        await this.requestNavigationDataFn(this.userGradeId)
      }
    } else {
      this.checking = false
    }
  },
  mounted() {
    window.addEventListener('scroll', () => {
      const ST = document.documentElement.scrollTop
      // 根据页面滚动状态区分样式
      const isScroll = ST > 94
      const endStr = isScroll ? '_scroll' : ''
      this.setNavClass('header_fixed', `header_fixed${endStr}`)
      this.setNavClass('page_links', `page_links${endStr}`)
      this.setNavClass('btn_name', `btn_name${endStr}`)
      this.setNavClass('active_link', `active_link${endStr}`)
      if (this.transParent) {
        this.navClass.arrowDown = isScroll ? arrowDown : arrowDownWhite
        this.navClass.iconLoading = isScroll ? iconLoading : iconLoadingWhite
      }
    }, false)
  },
  watch: {
    showDropList(val) {
      if (val) {
        if (this.cantDrop) return
        // 日志
        window.__XES_LOG__.clickMsg({
          params: {
            key: 'xes-mall-navigator',
            clickid: '2.1'
          }
        })
      }
    }
  },
  methods: {
    setNavClass(key, value) {
      this.navClass[key] = `${this.transParent ? 'immersive_' : 'no_immersive_'}${value}`
    },
    async requestUserDataFn() {
      // 校验登录态
      let loginRes
      const LOGIN_URL = this.isDebugger ? `${loginBaseURL}V1/Web/checkLogin` : `${this.envConfig.LOGIN_API_BASE}V1/Web/checkLogin`
      try {
        const { data } = await axios.get(`${LOGIN_URL}`, {
          withCredentials: true,
          params: {
            'X-Businessline-Id': this.bsenv
          }
        })
        loginRes = data
      } catch (error) {
        console.log('loginRes===error==', error)
        return
      }

      if (loginRes && !loginRes.stat) {
        this.checkLogin = false
        return
      }
      this.checkLogin = true

      let userinfoRes
      try {
        const { data } = await axios.get(
          this.isDebugger ? `${mallBaseURL}userInfo` : `${this.envConfig.MALL_API_BASE}userInfo`, {
            withCredentials: true,
            params: {
              'X-Businessline-Id': this.bsenv
            }
          }
        )
        userinfoRes = data
      } catch (error) {
        console.log('userInfo==error==', error)
        return
      } finally {
        // ...
      }
      if (userinfoRes && userinfoRes.result && userinfoRes.result.status === 9) {
        // 解析token异常
        this.checkLogin = false
        this.userGradeId = 0
      } else {
        this.checkLogin = true
        this.userData = userinfoRes
        this.userGradeId = userinfoRes.grade_id
      }
    },
    async requestNavigationDataFn(gradeId) {
      try {
        const stuAreaId = +Cookies.get('stu_area_id')
        const provinceID = xAreaIdEnum[stuAreaId] || 100
        const params = {
          login: this.checkLogin ? 1 : 0,
          grade: gradeId,
          province: provinceID,
          'X-Businessline-Id': this.bsenv
        }
        const { data } = await axios.post(
          this.isDebugger
            ? `${mallBaseURL}navigationnew`
            : `${this.envConfig.MALL_API_BASE}navigationnew`,
          params
        )

        if (data.stat === 1) {
          this.navData = data.data
          try {
            if (this.navData && this.navData.length) {
              // let curNavData = this.navData.map((item) => {
              //   if (item.type === this.pageType) {
              //     return item
              //   }
              // })
              const curNavData = this.getCurNavData(this.pageType)
              this.activeNavData = curNavData[0].nav
              this.isShowLoginBtn = curNavData[0].isShowLogin
              // 获取初始学习中心和侧边栏数据
              this.slideList = curNavData[0].slide
              this.navLoginList = curNavData[0].navLogin
            }
          } catch (e) {
            console.log(e)
          }
        } else {
          console.log('获取导航数据', data)
        }
      } catch (error) {
        console.log('获取导航数据api throw error==', error)
      }
    },
    getCurNavData(pageType) {
      let curNavData = this.navData.map(item => {
        if (item.type === pageType) {
          return item
        }
      })
      curNavData = curNavData.filter(item => item)
      return curNavData
    },
    toHome() {
      window.location.href = `${window.location.protocol}//${this.envConfig.PAGE_DOMAIN}`
    },
    clickToLogoutFn() {
      window.location.href = `${
        this.envConfig.LOGOUT_BASE_URL
      }?redirect_url=${encodeURIComponent(window.location.href)}`
    },
    handleDropItem() {
      this.$xesLogger.clickMsg({
        params: {
          key: 'xes-nav-login',
          eventname: '学习中心-点击',
          clickid: 'click_4Q43WKcW',
        }
      })
    },
    toLogin() {
      this.$xesLogger.clickMsg({
        params: {
          key: 'xes-nav-login',
          eventname: '登录/注册-点击',
          clickid: 'click_HDA0tGAY',
        }
      })

      window.location.href = `${
        this.envConfig.LOGIN_JUMP_BASE_URL
      }?redirect_url=${encodeURIComponent(window.location.href)}`
    },
    clickNavLink(url, isNewPage, type, childItemType) {
      if (!url) return
      if (+type === 9) {
        if (+childItemType === 91) {
          window.__XES_LOG__.clickMsg({
            params: {
              key: 'xes-nav-code',
              eventname: '素养生活-编程社区-点击',
              clickid: 'click_EMsEDJJX'
            },
          });
        } else if (+childItemType === 92) {
          window.__XES_LOG__.clickMsg({
            params: {
              key: 'xes-nav-box',
              eventname: '素养生活-素养盒子-点击',
              clickid: 'click_INIsI7v9'
            },
          });
        }
      }
      // target 1当前页  2新页面
      const tempData = this.getCurNavData(type)
      const recordPos = {}
      recordPos[tempData.type] = type
      localStorage.setItem('XESNAVTYPE', JSON.stringify(recordPos))
      console.log('url', url)
      console.log('recordPos', recordPos)
      if (isNewPage) {
        window.open(url)
      } else {
        window.location.href = url
      }
    },
    noShowDrop(val) {
      this.cantDrop = val
    },
    hoverUserName() {
      this.showDropList = true
    },
    outUserName() {
      this.showDropList = false
    }
  }
}
</script>

<style lang="scss" scoped>
.immersive {
  // 沉浸式的样式
  &_header_fixed, &_header_fixed_scroll {
    position: fixed;
    top: 0;
    z-index: 1000;
    width: 100vw;
    background-color: transparent;
  }

  &_header_fixed_scroll {
    background-color: #ffffff;
  }

  &_active_link {
    color: #fff;

    &::after {
      background-color: #fff;
    }
  }

  &_active_link_scroll {
    color: #e94040 !important;

    &::after {
      background-color: #e94040 !important;
    }
  }

  &_page_links {
    color: #fff;

    &:hover {
      color: #fff !important;
    }
  }

  &_page_links_scroll {
    color: #000;
  }

  &_btn_name {
    color: #fff;
  }

  &_btn_name_scroll {
    color: #000;
  }
}

.no_immersive {
  &_nav_container {
    width: 100vw;
    height: 94px;
    background-color: #fff;
  }

  &_header_fixed {
    position: fixed;
    width: 100vw;
    top: 0;
    z-index: 1000;
    background-color: #fff;
    box-shadow: none;
  }

  &_header_fixed_scroll {
    position: fixed;
    width: 100vw;
    top: 0;
    z-index: 1000;
    background-color: #fff;
    box-shadow: 2px 2px 6px 0 hsla(0, 0%, 80%, .5) !important;
  }

  &_active_link, &_active_link_scroll {
    color: #e94040 !important;

    &::after {
      background-color: #e94040 !important;
    }
  }

  &_page_links, &_page_links_scroll {
    color: #000;
  }

  &_btn_name, &_btn_name_scroll {
    color: #000;
  }
}

.pc_header {
  &-content {
    margin: 0 auto;
    width: 1200px;
    height: 94px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &-logo {
      display: block;
      height: 52px;
      width: 207px;
      flex-shrink: 0;
    }

    &-right {
      display: flex;
      align-items: center;
      height: 94px;
      flex-grow: 1;
      justify-content: flex-end;
      // flex-grow: 1;
      .page_links {
        display: flex;
        align-items: center;
        position: relative;
        font-size: 19px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        margin-left: 50px;
        padding: 11px 0;
        cursor: pointer;
        white-space: nowrap;

        &-arrow {
          width: 20px;
          height: 20px;
          transition: transform 0.3s ease;
        }

        &:hover {
          color: #e02727;

          .nav_link-box {
            display: block;
          }

          .page_links-arrow {
            transform: rotate(180deg);
          }

          .user_btn-arrow {
            transform: rotate(180deg);
          }

          .user__dropmenu {
            display: block;
          }
        }
      }

      .active_link {
        font-size: 18px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        position: relative;

        &::after {
          content: '';
          position: absolute;
          bottom: -14px;
          left: 50%;
          transform: translateX(-50%);
          display: block;
          width: 20px;
          height: 2px;
          //background-color: #e94040 !important;
          border-radius: 1px;
        }
      }

      .user_btn {
        position: relative;
        cursor: pointer;
        display: inline-flex;
        align-items: center;
        margin-left: 50px;
        padding: 11px 0;
        white-space: nowrap;

        &-avatar {
          display: inline-block;
          width: 20px;
          background-color: #d8d8d8;
          height: 20px;
          border-radius: 50%;
          overflow: hidden;
          background-size: cover;
          margin-right: 2px;
        }

        &-name {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
        }

        &-arrow {
          width: 20px;
          transition: transform 0.3s ease;
        }

        &:hover {
          .user_btn-arrow {
            transform: rotate(180deg);
          }

          .user__dropmenu {
            display: block;
          }
        }
      }

      .login_btn {
        margin-left: 60px;
        width: 112px;
        background-color: #F93834;
        height: 41px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #ffffff;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        text-align: center;
        border-radius: 21px;
        cursor: pointer;

        &:hover {
          box-shadow: 1px 1px 8px rgba($color: #000000, $alpha: 0.2);
        }

        &-white {
          color: #e02727;
          background-color: #fff;

          &:hover {
            background-color: #e7ebff !important;
          }
        }
      }
    }
  }
}

.user__dropmenu {
  display: none;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 0;
  padding-top: 70px;
  bottom: 0;
  box-sizing: border-box;

  &:hover {
    display: block !important;
  }

  .user__dropmenu-block {
    width: 100%;
    background-color: #ffffff;
    box-shadow: 0px 0px 12px 4px rgba(204, 204, 204, 0.2);
    border-radius: 4px;
    position: relative;
    box-sizing: border-box;
  }

  .menu__item {
    padding: 16px 20px;
    display: block;
    font-family: PingFangSC-Regular, PingFang SC;
    font-size: 16px;
    color: #000000;
    text-decoration: none;
    transition: color 0.3s;
    font-weight: 400;
    cursor: pointer;

    &.active {
      color: #e02727;
    }

    &:hover {
      color: #e02727;
    }
  }

  .menu__item:first-child {
    padding-top: 24px;
  }

  .menu__item:last-child {
    padding-bottom: 24px;
  }
}

@keyframes loop {
  0% {
    transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
  }
  100% {
    transform: rotate(-360deg);
    -ms-transform: rotate(-360deg);
    -webkit-transform: rotate(-360deg);
  }
}

.checking {
  margin-left: 50px;
  display: flex;
  align-items: center;

  img {
    width: 25px;
    animation: loop 1s infinite linear;
  }
}

.white_thme {
  color: #fff !important;

  &::after {
    background-color: #fff !important;
  }

  &:hover {
    color: #e7ebff !important;

    &::after {
      background-color: #e7ebff !important;
    }
  }
}

.nav_link {
  text-decoration: none;
  display: block;
  font-size: 18px;
  color: #000000;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  transition: color 0.3s;
  padding: 10px 15px;
  cursor: pointer;

  &:hover {
    display: block;
  }

  &-box {
    display: none;
    width: 100%;
    position: absolute;
    left: 50%;
    top: 45px;
    z-index: 1000;
    transform: translateX(-50%);
    background-color: #ffffff;
    box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.05);
    border-radius: 4px;

    &-link {
      display: block;
      line-height: 44px;
      color: #333;
      font-size: 14px;
      text-align: center;
      border-bottom: 1px solid #e5e5e5;
      text-decoration: none;
      font-family: PingFangSC-Regular;

      &:hover {
        color: #e02727;
      }
    }
  }
}
</style>
