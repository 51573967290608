
import Vue from 'vue'
import router from '@router'
import app from '@app'
import initLog from '@utils/log'
import VueLazyload from 'vue-lazyload'
import './utils/server-wokers'
// import VueAwesomeSwiper from 'vue-awesome-swiper'
// import 'swiper/css/swiper.css'
import 'wowjs/css/libs/animate.css'
import "@utils/tween";
import "@utils/animate";
// Vue.use(VueAwesomeSwiper)
Vue.config.productionTip = false
Vue.use(VueLazyload, {
  preLoad: 1,
  loading: require("@assets/home/loading.svg"),
  error: require("@assets/home/loading.svg"),
  attempt: 1
});

Vue.prototype.$global = {
  // 年级 默认年级三年级 8岁
  currentGrade: 4,
  // 是否登陆
  isLogin: false
}
Vue.prototype.$eventBus = new Vue();

(async function () {
  initLog(Vue)

  const isIE = window.ActiveXObject || 'ActiveXObject' in window
  let vm;
  if (isIE) {
    const component = await import('@components/version-update')
    const IS_IE = component.default
    vm = new Vue({
      render: h => h(IS_IE)
    })
    vm.$mount('#app')
    return
  }
  vm = new Vue({
    router,
    render: h => h(app)
  })
  vm.$mount('#app')
})()
