import XesLoggerSDK from "@xes/xes_fe_log";

function initLog(Vue) {
  Vue.use(XesLoggerSDK, {
    cacheUploader: {
      interval: 10,
      batch: 1
    },
    common: {
      eventid: "mallwww"
    },
    network: {
      open: true,
      sample: 1
    },
    clickMsg: {
      open: true
    },
    loadMsg: {
      open: false // 自动展现日志开关
    },
    runtime: {
      open: true,
      sample: 1
    },
    resource: {
      open: true,
      sample: 1
    },
    hashchange: {
      open: false, // hashchange自动展现日志开关
      pvid: "hash",
      pageuid: {
        // 展现日志、交互日志pageuid路由映射
        index: "pageindex" // 首页路由pageuid映射
      }
      // loadParam: {                     // 展现日志私有字段路由映射
      //   'index': {'key1': 'xxx'},    // 首页路由展现日志私有字段映射
      //   'path': {'key1': 'yyy'},
      //   'path/a': {'key1': 'zzz'}
      // }
    },
    performance: {
      open: true,
      sample: 1
    },
    vue: {
      errorHandler: true
    },
    trace: {
      open: true
    },
    appid: {
      localhost: {
        appid: "1004991",
        appkey: "651549f8600025626961a0eb91dcd860"
      },
      "iii.xueersi.com": {
        appid: "1004991",
        appkey: "651549f8600025626961a0eb91dcd860"
      },
      "online.xueersi.com": {
        appid: "1000012",
        appkey: "651549f8600025626961a0eb91dcd860"
      }
    },
    param: {
      // 'key': '200021'                 // 展现日志、交互日志公共字段
    }
  });
}

export default initLog;
