<template>
  <div class="progress__top__loading" :class="[ canSuccess ? 'success' : 'fail' ]" :style="{ opacity: show ? 1 : 0, width: percent + '%' }"></div>
</template>

<script>
export default {
  data () {
    return {
      show: false, canSuccess: true, percent: 0,
    }
  },
}
</script>

<style lang="scss" scoped>
.progress__top__loading {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 99999;
  opacity: 1;
  height: 3px;
  transition: width 200ms, opacity 400ms;
}
.progress__top__loading.success {
  background-color: #ffca2b;
}
.progress__top__loading.fail {
  background-color: #da1748;
}
</style>
