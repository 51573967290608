const xAreaIdEnum = {
  11: 1,
  12: 2,
  13: 3,
  14: 4,
  15: 5,
  21: 6,
  22: 7,
  23: 8,
  31: 9,
  32: 10,
  33: 11,
  34: 12,
  35: 13,
  36: 14,
  37: 15,
  41: 16,
  42: 17,
  43: 18,
  44: 19,
  45: 20,
  46: 21,
  50: 22,
  51: 23,
  52: 24,
  53: 25,
  54: 26,
  61: 27,
  62: 28,
  63: 29,
  64: 30,
  65: 31,
  66: 32,
  81: 33,
  82: 34
}

const invert = json => {
  let ret = {}
  for (let key in json) {
    ret[json[key]] = key
  }
  return ret
}

const invertXAreaIdEnum = invert(xAreaIdEnum)
export { xAreaIdEnum, invertXAreaIdEnum }
