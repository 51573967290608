import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const originalPush = VueRouter.prototype.push;

VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err);
};
const resolve = page => () => import(`@views/${page}.vue`);

export default new VueRouter({
  mode: "history",
  routes: [
    {
      path: "/select-course",
      component: resolve("select-course"),
      name: "select-course"
    },
    {
      path: "/d",
      component: resolve("download"),
      name: "download"
    },
    {
      name: "old-index",
      component: resolve("old-index"),
      path: "/old-index/:oldGradeId?/:subjectAlias?"
    },
    // {
    //   name: 'index',
    //   component: resolve('index'),
    //   path: '/',
    // },
    {
      name: "index",
      component: resolve("index/index"),
      path: "/"
    },
    {
      name: "about",
      component: resolve("about/index"),
      path: "/about"
    },
    {
      name: "life",
      component: resolve("life/index"),
      path: "/life"
    },
    {
      name: "box",
      component: resolve("life/box/index"),
      path: "/box"
    },
    {
      name: "humanities",
      component: resolve("attainment/humanities/index"),
      path: "/humanities"
    },
    {
      name: "code",
      component: resolve("attainment/code/index"),
      path: "/code"
    },
    {
      name: "science",
      component: resolve("attainment/science/index"),
      path: "/science"
    },
    {
      name: "eloquence",
      component: resolve("attainment/eloquence/index"),
      path: "/eloquence"
    },
    {
      name: "naturalScience",
      component: resolve("attainment/naturalScience/index"),
      path: "/naturalScience"
    },
    {
      name: "create",
      component: resolve("attainment/create/index"),
      path: "/create"
    },
    {
      name: "art",
      component: resolve("attainment/art/index"),
      path: "/art"
    },
    {
      name: "chess",
      component: resolve("attainment/chess/index"),
      path: "/chess"
    }
  ]
});
